/*
  COPY THESE INSTRUCTIONS TO ALL NEW LANGUAGE CONFIG FILES (FOR EASY ACCESS)

Three files need their import statements altered for a Language Switch
1. App.scss - handles all styling changes
2. App.js - Enabling contents to change throughout the app to fit the Language
3. Store/firestore.js - Enables data from correct firestore database
  */

const tabNameWelcome = "kawóʔ ciklí‧n";
const groupName = "Colville";
const tabNameDictionary = "Dictionary";
const firebaseConfig = {
  apiKey: "AIzaSyBOCDfUIZ7V6JkoYPnH47lN3Juay7-mMNc",
  authDomain: "colville-tit.firebaseapp.com",
  databaseURL: "https://colville-tit.firebaseio.com",
  projectId: "colville-tit",
  storageBucket: "blackfootmedia",
  messagingSenderId: "649134109147",
  appId: "1:649134109147:web:7fcdcf50f427bd78a137fe",
  measurementId: "G-BJY62SWFH9",
};

//=====> This is the main bar <====//
const linkArray = [
];

const welcomeEngArray = [
  "Welcome to the tito·qatímt (Nez Perce) online dictionary. We hope this site helps you with learning and understand our language. We include one of the three language dialects of the Colville Tribe. We want this to be an inclusive language tool for all to use and share. ",
  "First we give thanks to everyone. Thanks to our creator and to our ancestors for all that they have done and given to us. We give thanks to our elders that have worked tirelessly to share their knowledge with us so that our language can live on.",
  " ʔehé ta̓̓c ʔeé pá·yn, kí· hí·wes tito·qatímt tí·mesne nó·n̓ax sapá·wapayatat hité·metuʔ ká· cukwenú ̓tito·qatímtsix Colvillenime nó·n ̓ax kí· ʔóykalo ̓ayn ʔú·yitpe liló·ycix ʔoykalo·ma himé·qis qeʔciyéw ̓yew ̓ nú·nim hanyaw ̓a·t ka· titlú·me hipekúye ka· hipené·c ̓niye qeʔciyéw ̓yew ̓né·wit lóx̣c té·qisme cukwené·witne ka· c ̓í·qin kunk̓u ̓ayn ",
  " Anne George",
  " Ida Desautel",
  " Frank Andrews",
  " Agnes Andrews",
  " Frank Halfmoon",
  " Albert Andrews",
  " Milton Davis",
];

const welcomeLangArray = [
  // { text: "Oki kanaitapi", audio: "hello_everyone.mp3" },
  // { text: "Ihsukapi kitsinohtsi", audio: "glad_to_see_you_all.mp3" },
  // {
  //   text: "Tsiikohtaahsi'tsihp iihtootaaatsiiyo'p",
  //   audio: "i_am_happy_you_all_come_here.mp3",
  // },
  // { text: "Iitamiksistikoo", audio: "its_happy_day.mp3" },
  // { text: "Stamssaakiitsi'poyit", audio: "try_to_speak_blackfoot.mp3" },
];

const welcomeLogoArray = [
  "logo-nta",
];

const welcomeMessageArray = [
  "Developed by Native Teaching Aids, in conjunction with the Confederated Tribes of the Colville",
];

const config = {
  linkArray: linkArray,
  engArray: welcomeEngArray,
  langArray: welcomeLangArray,
  logoArray: welcomeLogoArray,
  messageArray: welcomeMessageArray,
  tabNameWelcome: tabNameWelcome,
  tabNameDictionary: tabNameDictionary,
  dictionaryTitle: tabNameDictionary,
  firebaseConfig: firebaseConfig,
};

export default config;
export { linkArray, welcomeEngArray, welcomeLangArray, firebaseConfig };
